
.viewportWrapper {
  margin-left: -20px;
  box-shadow: rgba(0, 0, 0, 0.2) 0 1px, rgba(0, 0, 0, 0.2) 0 -1px;
  width: calc(100% + 40px);
  height: calc(100vh - 400px);
  min-height: 200px;
  max-height: 600px;
  overflow: hidden;
}

:global(body.kl-theme-dark) {
  .viewportWrapper {
    box-shadow: 0 1px #636363, 0 -1px #636363;
  }
}
